@use "~@vp/swan/tokens";

:root {
  --teams-form-width: 320px;
}

head,
body,
#team-details-page,
.swan-site-content {
  position: relative;
  width: 100vw;
  height: 100vh;
}

#team-details-page {
  height: 100vh;
  width: 100vw;
  position: relative;

  .swan-site-content {
    height: 100%;
    width: 100%;

    .teams-app {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: grid;

      grid-template-rows: min-content auto 140px;
      grid-template-areas:
        "header"
        "details"
        "footer";

      @media #{tokens.$swan-sem-mq-eq-xs} {
        grid-template-rows: min-content auto 195px;
      }

      .teams-details-header {
        grid-area: header;
      }

      .alert-collection {
        height: min-content;
      }

      .teams-details-container {
        grid-area: details;
        height: max-content;
        width: fit-content;
        max-width: 100%;
        justify-self: center;
        overflow: auto;

        @media #{tokens.$swan-sem-mq-gte-sm} {
          height: auto;
        }

        .team-details-preview-link-button {
          min-width: max-content;
        }

        .team-details-add-non-personalized-link-button {
          width: max-content;
        }
      }

      .teams-details-form-container {
        overflow-x: auto;
        min-width: 100px;
        min-height: 100px;

        @media #{tokens.$swan-sem-mq-gte-sm} {
          margin: auto;
          width: fit-content;
          max-width: 100%;
        }

        .teams-details-form-panel {
          min-width: var(--teams-form-width);

          @media #{tokens.$swan-sem-mq-gte-sm} {
            min-width: auto;
          }

          .teams-details-form-entry {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;

            @media #{tokens.$swan-sem-mq-gte-sm} {
              flex-wrap: nowrap;

              .teams-details-entry-number {
                display: inline-block;
                min-width: 2em; // fixed width when less than 2 digits
                margin-top: 1em;
              }
            }

            @media #{tokens.$swan-sem-mq-lt-sm} {
              .teams-details-quantity-wrapper {
                display: contents;
              }
            }
          }

          .teams-details-form-field {
            width: 100%;

            @media #{tokens.$swan-sem-mq-gte-sm} {
              flex-shrink: 0;
              width: 12.5em;
            }
          }

          .teams-details-quantity-warnings {
            // allow the warnings to reach past left, but not beyond the second last field
            p {
              width: calc(200% + tokens.$swan-sem-space-5);
              text-align: right;
            }
          }

          .teams-details-size-field,
          .teams-details-quantity-field-size {
            width: 48%;
            min-width: unset;

            @media #{tokens.$swan-sem-mq-gte-sm} {
              width: 7em;
            }
          }

          .teams-details-quantity-field-hard {
            width: 100%;

            @media #{tokens.$swan-sem-mq-gte-sm} {
              width: 7em;
            }
          }
        }
        .teams-details-form-panel:first-child {
          @media #{tokens.$swan-sem-mq-gte-sm} {
            .teams-details-entry-number {
              margin-top: 2.25em; // fixed mt to top-align the entry number for first entry
            }

            button {
              margin-top: 1.75em; // fixed mt to top-align the entry number for first entry
            }
          }
        }
      }

      .teams-footer {
        align-self: stretch;
        height: 100%;
        grid-area: footer;
        box-shadow: tokens.$swan-sem-elevation-overlay;
        z-index: 1;

        > .swan-bounded-content {
          display: grid;
          height: inherit;
          grid-template-columns: 1fr 3fr 1fr;
          grid-template-areas: "edit price proceed";
          align-items: stretch;

          .teams-footer-edit {
            grid-area: edit;
            justify-self: center;
            align-self: center;
          }
          .teams-footer-price {
            grid-area: price;
            justify-self: right;
            align-self: center;
            .pricing-summary {
              height: fit-content;
            }
          }
          .teams-footer-proceed {
            grid-area: proceed;
            justify-self: center;
            align-self: center;
          }

          @media #{tokens.$swan-sem-mq-gte-xl} {
            width: 1500px;
          }

          @media #{tokens.$swan-sem-mq-lte-lg} {
            width: 80%;
          }

          @media #{tokens.$swan-sem-mq-lte-md} {
            width: 100%;
          }

          @media #{tokens.$swan-sem-mq-lte-sm} {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: "price proceed";

            .teams-footer-edit {
              display: none;
            }

            .teams-footer-price {
              justify-self: left;
            }

            .teams-footer-proceed {
              justify-self: stretch;
            }
          }

          @media #{tokens.$swan-sem-mq-eq-xs} {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas: "price" "proceed";
            row-gap: 0;
          }
        }
      }

      .teams-example-table {
        overflow-x: auto;
        width: 100%;
      }
    }
  }
}

// Note: due to the custom layout applied on the review page, this styling is required for the ModalDialogCloseButton to be displayed on the page.
.review-close-button {
  z-index: inherit !important;
}

.review-body {
  @media #{tokens.$swan-sem-mq-gte-sm} {
    height: 100vh;
    overflow: hidden;
    .review-row {
      height: 100%;
      .review-preview-section {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .review-and-acknowledge {
        border-left-style: solid;
        border-left-color: tokens.$swan-sem-color-border-standard;
        border-left-width: tokens.$swan-sem-border-width-standard;
      }
    }
  }
}

.review-buttons-container {
  .review-add-to-cart-button {
    order: 1 !important;
  }

  .review-edit-design-button {
    order: 2 !important;
  }
}

.preview-modal,
.review-modal {
  .modal-navigation-wrapper {
    justify-content: end;
  }

  .preview-block {
    .preview-block-previews-wrapper {
      justify-content: center;
    }

    .preview-modal-size-chart {
      .preview-modal-size-chart-content {
        @media #{tokens.$swan-sem-mq-gt-xs} {
          max-width: 50%;
          min-width: 50%;
        }
      }
    }

    .preview-modal-size-chart::backdrop {
      background-color: rgba(0, 0, 0, 0.3) !important;
    }

    .zoomable-image-callout {
      position: absolute;
      top: tokens.$swan-sem-space-3;
      left: tokens.$swan-sem-space-3;
    }
  }
}
